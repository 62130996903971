<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
// 点击效果
document.body.onclick = function (e) {
  const div = document.createElement('div')
  const duration = 5
  div.style = `font-weight: 900; color: #408a38; animation: fade ${duration}s forwards;position: fixed; left: ${e.clientX}px; top: ${e.clientY - 20}px; z-index: 999999999999;`
  div.innerText = `HP +${Math.floor(Math.random() * 100)}`
  document.body.appendChild(div)

  setTimeout(() => {
    document.body.removeChild(div)
  }, duration * 1000)
}
</script>

<style lang="scss" scoped>
#app {
  font-family: $font-family;
}

// * {
//   cursor: url('./assets/torch.png'), auto;
// }
</style>
