<template>
  <div>
    <m-nav />
    <router-view />
    <m-footer />
  </div>
</template>

<script>
import MNav from '@/components/MNav'
import MFooter from '@/components/MFooter'

export default {
  components: {
    [MNav.name]: MNav,
    [MFooter.name]: MFooter
  }
}
</script>
