import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home')
  },
  {
    path: '/layout',
    name: 'layout',
    redirect: '/',
    component: Layout,
    children: [
      {
        path: '/crowdfunding',
        name: 'crowdfunding',
        component: () => import('@/views/Crowdfunding')
      },
      {
        path: '/develop',
        name: 'develop',
        component: () => import('@/views/Develop')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
