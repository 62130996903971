<template>
  <b-container class="footer">
    <div class="img-wrap">
      <a href="https://twitter.com/MetaDoge_io" target="_blank">
        <img class="img" :src="require('@/assets/icon_twitter.svg')" >
      </a>
      <a href="https://t.me/NFTLOOT" target="_blank">
        <img class="img" :src="require('@/assets/icon_telegram.svg')" >
      </a>
      <a href="https://discord.gg/Z4SHMCpeMj" target="_blank">
        <img class="img" :src="require('@/assets/icon_discord.svg')" >
      </a>
      <a href="mailto:metaverse279@gmail.com" target="_blank">
        <img class="img mr-0" :src="require('@/assets/icon_email.svg')" >
      </a>
    </div>
    <p class="p">VERIFIED SMART CONTRACT ADDRESS：0x9DD89B365A447C7673Cb4495e9dDF7fdac36d072</p>
    <p class="copyright">© 2021 Metadoge Labs</p>
  </b-container>
</template>

<script>
export default {
  name: 'MFooter'
}
</script>

<style lang="scss" scoped>
@keyframes jump {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}
.footer {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  text-align: center;
  color: #fff;
  font-weight: 400;

  .img-wrap {
    a {
      display: inline-block;
      margin-right: 2.5rem;
      margin-bottom: 1.68rem;

      &:nth-last-of-type(1) {
        margin-right: 0;
      }
    }

    .img {
      &:hover {
        animation: jump .4s ease;
      }
    }
  }

  .p {
    color: inherit;
    margin-bottom: 1.875rem;
  }

  .copyright {
    font-size: .75rem;
  }
}
</style>
