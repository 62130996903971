<template>
  <b-container>
    <b-navbar sticky toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand>
        <img :src="require('@/assets/logo.png')" @click="toHome" style="cursor: pointer" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <!-- <b-nav-item @click="toHome">Home</b-nav-item> -->
          <b-nav-item-dropdown text="Feature" right>
            <b-nav-text v-for="feature in features" class="dropdown-item" style="color: #000"
              :key="feature.href"
              @click="handleClick(feature.href)">{{ feature.label }}</b-nav-text>
          </b-nav-item-dropdown>
          <b-nav-item @click="handleClick('nft')">NFT</b-nav-item>
          <b-nav-item @click="handleClick('roadmap')">Roadmap</b-nav-item>
          <b-nav-item @click="$router.push('/crowdfunding')"
            >Crowdfunding</b-nav-item
          >
          <b-nav-item @click="handleDevelop">Marketplace</b-nav-item>
          <b-nav-item @click="handleDevelop">Arena</b-nav-item>
          <b-nav-item @click="handleDevelop">Community</b-nav-item>
        </b-navbar-nav>
        <b-button class="ml-auto nav-button" variant="primary" @click="handleConnect"
          >{{ connectBtn }}</b-button
        >
      </b-collapse>
    </b-navbar>
  </b-container>
</template>

<script>
import Web3 from 'web3'
const defaultBtn = 'Connect Wallet'

export default {
  name: 'MNav',
  data () {
    return {
      features: [
        {
          href: 'intro',
          label: 'Game Intro'
        },
        {
          href: 'ecology',
          label: 'Game Ecology'
        },
        {
          href: 'model',
          label: 'Economic model'
        }
      ],
      accounts: null,
      netId: null,
      connectBtn: defaultBtn,
      errMsg: '',
      errTitle: ''
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    accounts () {
      this.connectBtn = this.formatAccount()
    },
    netId () {
      this.connectBtn = this.formatAccount()
    }
  },
  methods: {
    init () {
      const web3js = new Web3(window.ethereum)
      web3js.eth.net.getId((error, result) => {
        // 判断网络是否是ETH主网 或BSC 主网
        if (error) throw new Error(error)
        this.netId = result
      })

      web3js.eth.getAccounts((error, accounts) => {
        if (error) throw new Error(error)
        this.accounts = accounts[0]
      })

      // 监听当前以太网络ID的变化
      window.ethereum.on('networkChanged', netId => {
        this.netId = netId
        // location.reload()
      })

      // 监听当前以太地址的变化
      window.ethereum.on('accountsChanged', accounts => {
        this.accounts = accounts[0]
        // location.reload()
      })
    },
    handleClick (anchor) {
      if (anchor) {
        const current = this.$route.query.anchor
        if (anchor !== current) {
          this.$router.push({ path: '/', query: { anchor } })
        }
      }
    },
    toHome () {
      if (this.$route.path !== '/' || 'anchor' in this.$route.query) {
        this.$router.push('/')
      }
    },
    // 链接钱包
    handleConnect () {
      this.$connectWallet.getAddress().then((v) => {
        if (v.address.length !== 0) {
          // 获得用户授权账号
          if (v.netID === 1) {
            // 是以太主网
          } else {
            this.$bvToast.toast('请将MetaMask钱包链接到以ETH主网', {
              title: '钱包主网错误',
              solid: true,
              variant: 'info'
            })
          }
        } else {
          // 地址长度为0 时，也就是未授权时
          this.$bvToast.toast('请再次尝试或检查连接问题', {
            title: '连接MetaMask失败',
            solid: true,
            variant: 'info'
          })
        }
      })
    },
    formatAccount () {
      if (+this.netId === 1 && this.accounts) {
        return `${this.accounts.slice(0, 6)}...${this.accounts.slice(-3)}`
      } else {
        return defaultBtn
      }
    },
    handleDevelop () {
      if (this.$route.path !== '/develop') this.$router.push('/develop')
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.dropdown-item {
  color: #000;
  cursor: pointer;
  padding: 8px 12px;
}

.nav-button {
  font-size: 16px;
  // letter-spacing: -1px;
  // font-family: terminal-grotesque, sans-serif;
}
</style>
