import Web3 from 'web3/dist/web3.min.js'
// const web3 = new Web3(Web3.givenProvider || 'ws://some.local-or-remote.node:8546')

const getAddress = async function () {
  const data = {
    address: '',
    netID: ''
  }

  var web3Provider
  if (window.ethereum) {
    web3Provider = window.ethereum
    try {
      // 请求用户授权成功
      await window.ethereum.enable()
    } catch (error) {
      // 用户不授权时
      console.error('User denied account access')
      // window.alert("User denied account access")
      throw new Error(error)
    }
  } else if (window.web3) {
    // 老版 MetaMask Legacy dapp browsers...
    web3Provider = window.web3.currentProvider
  } else {
    web3Provider = new Web3.providers.HttpProvider('http://localhost:8545')
  }

  const web3js = new Web3(web3Provider) // web3js就是你需要的web3实例

  // 获取主网ID
  await web3js.eth.net.getId((error, result) => {
    // console.log(result);
    // 授权成功后result能正常获取到账号了
    data.netID = result

    console.error(error)
  })

  // 获取地址和根据netID 判断type类型
  await web3js.eth.getAccounts((error, result) => {
    // console.log(result[0]); //授权成功后result能正常获取到账号了
    data.address = result
    console.error(error)
  })

  return data
}

export default {
  getAddress
}
